import styled from "styled-components";
import "./../pages/style.css";

const Buttons = styled.button`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");
  font-family: "DM Sans", sans-serif;
  
  min-width:85px;
  border-radius: 5px;
  text-decoration: "none";
  border: 1px solid black;
  background-color: white;
  font-size: 14px;

  &.black {
    background-color: black;
    color: white;
    border: 1px solid transparent;

    font-weight: 400;
  }

  &:hover {
    color: black;
    background-color: white;
    border: 1px solid black;

    font-weight: 600;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 0.9em;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 1empx;
  }
`;

export function Button(props) {
  const {type, disabled, colorBg, text, isHidden, url, onClick, styles = ''} = props;

  return (
    <Buttons type={type} disabled={disabled} className={`me-2 text-nowrap mb-2 pe-3 ps-3 ${colorBg === "black" && "black"} ${!text && "d-none"} ${isHidden ? 'd-none' : ''} ${styles && styles}`} href={url} onClick={onClick}>
      {props.text}
    </Buttons>
  );
}
