import { useState, useContext, useEffect } from "react";
import styled from "styled-components"
import { Link, useLocation } from "react-router-dom"

import AuthContext from "./shared/AuthContext";
import { SearchBar } from "./SearchBar"

import * as notificationsData from "../data/notifications";

import { FaRegNewspaper, FaBell, FaChevronDown, FaHeart, FaRegHeart, FaRegUser, FaUser } from "react-icons/fa6";
import { HiOutlineCog8Tooth } from "react-icons/hi2";

import icNotification from '../assets/images/ic_notification.svg'
import { useScrollDirection } from "../hooks/scrollDirectionCheck";

const TopNav = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: ${(props) => props.top ? props.top : '0'};
  overflow: hidden;
  background: white;
  z-index: 100;
  transition: top 0.5s ease;

  padding: 0 1rem;
  margin: 0;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  .top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .btn-top {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
  }

  .notification {
    position: relative;
  }

  
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
const MenuItems = styled.div`
  font-size: 1rem;
  font-weight: 600;

  border-bottom: ${(props) => (props.currentLocation === props.href ? '3px solid #212529' : '3px solid transparent')};
  padding: 1rem 0;
`;

const NotifCount = styled.span`
  height: 18px;
  line-height: 11px;
  min-width: 18px;
  font-size: 10px;
`;

const UsernameContainer = styled.div`
  display: flex;
  flex-direction: row;
  // align-items: center;
  padding: 1rem 0;
  gap: 10px;

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    height: fit-content;
  }
`;

const NullContainer = styled.div`
  padding: 1rem 0;
`;

const RightTopNavbar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 3px 5px;
`;

export function TopNavigation(props) {
  const { toggleBottomMenuVisibility } = props;

  const location = useLocation();
  const { user } = useContext(AuthContext)

  const currentPage = window.location.pathname;

  const scrollDirection = useScrollDirection()

  const [userNotif, setUserNotif] = useState([]);

  const rightMenus = [
    {
      name: 'For You',
      href: '/',
    },
    {
      name: 'Top Buddies',
      href: '/top-buddies',
    },
    {
      name: 'News',
      href: '/news',
    },
    {
      name: 'Articles',
      href: '/articles',
    }
  ]

  useEffect(() => {
    const fetchNotif = async() => {
      const res = await notificationsData.getNotifications("?limit=20")
      
      if (res.code !== 200) {
        return;
      }
      const filteredUnreadNotif = res.data.filter((item) => !item.isRead)
      setUserNotif(filteredUnreadNotif);
    }

    if (user) fetchNotif()
  }, [user])

  return(
    <TopNav top={scrollDirection === 'down' ? '-6rem' : '0'} currentLocation={location.pathname} currentUser={user?.username}>
      <div className="m-0">
        {location.pathname === '/articles' 
        ? <SearchBar placeholder="Where do you travel?" page={window.location.pathname.includes('/news') ? 'news' : 'article'} /> 
        : location.pathname === '/' || location.pathname === '/top-buddies' 
          ? 
            <MenuContainer>
              {rightMenus.length > 0 
              ? rightMenus.slice(0, 2).map((menu, index) => 
                <MenuItems key={index} currentLocation={location.pathname} href={menu.href}>
                  <Link to={menu.href}>{menu.name}</Link>
                </MenuItems>) 
              : ''}
            </MenuContainer>
          : location.pathname === '/news' 
            ? <MenuContainer>
                {rightMenus.length > 0 
                ? rightMenus.slice(2, 4).map((menu, index) => 
                  <MenuItems key={index} currentLocation={location.pathname} href={menu.href}>
                    <Link to={menu.href}>{menu.name}</Link>
                  </MenuItems>) 
                : ''}
              </MenuContainer>
            : location.pathname === '/user-profile/' + user?.username
              ? <UsernameContainer>
                  <h3>{user?.username}</h3>
                  <button type="button" onClick={toggleBottomMenuVisibility}>
                    <FaChevronDown style={{width: 12, height: 12, marginTop: "0px"}} />
                  </button>
                </UsernameContainer>
              : <NullContainer>&nbsp;</NullContainer>
        }
      </div>

      <div className="top-nav">
        {currentPage.includes('/user-profile') || currentPage.includes('/favorite')
        ? 
          <RightTopNavbar>
            <Link to={`/favorite/liked`}>
              {currentPage.includes('/favorite') 
                ? <FaHeart style={{ width: 22, height: 22 }} /> 
                : <FaRegHeart style={{ width: 22, height: 22 }} />
              }
            </Link>
            <button type="button" className="btn-top" onClick={toggleBottomMenuVisibility}>
              <HiOutlineCog8Tooth style={{ width: 25, height: 25 }} />
            </button>
          </RightTopNavbar>
        : currentPage.includes('/articles') || currentPage.includes('/contributors')
          ? <RightTopNavbar>
              <Link to={`/contributors`}>
                {currentPage.includes('/contributors') 
                  ? <FaUser style={{ width: 20, height: 20 }} /> 
                  : <FaRegUser style={{ width: 20, height: 20 }} />
                }
              </Link>

              <Link to={`/news`}>
                <FaRegNewspaper style={{ width: 20, height: 20 }} />
              </Link>
            </RightTopNavbar>
          : <a href={user ? '/notification' : '#authModal'} data-bs-toggle={user ? '' : 'modal'} className="btn-top notification">
              {location.pathname === '/notification' 
              ?
                <FaBell style={{width: 20, height: 20}} />
              :
                <button className="btn-top">
                  <img src={icNotification} width={20} height={20} alt="icon_notification" />
                </button>
              }
              {user && (
                <NotifCount className="position-absolute top-5 p-1 translate-middle badge rounded-pill bg-danger">
                  {userNotif.length < 10 ? userNotif.length : "10+"}
                </NotifCount>
              )}
            </a>
        }
      </div>
    </TopNav>
  )
}